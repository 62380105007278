<template>
  <nav class="navbar fixed-top navbar-expand-lg" id="nav">
    <!-- <v-container> -->
    <router-link to="/" class="navbar-brand"><img src="https://bit.ly/3M0yRIT" height="80px" /></router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">
        <i class="fas fa-bars" style="color: #fff; font-size: 28px"></i>
      </span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link class="nav-link" to="/">{{ $t("home") }}</router-link>
        </li>
        <li class="nav-item dropdown">
          <router-link to="/expertise" class="nav-link">
            <span class="dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              {{ $t("expertise") }}
            </span>
          </router-link>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link to="/expertise/patent">
              <span class="dropdown-item">{{
                  $t("expertise_1")
              }}</span>
            </router-link>
            <router-link to="/expertise/trademark"><span class="dropdown-item">{{
                $t("expertise_2")
            }}</span></router-link>
            <router-link to="/expertise/industrial-design"><span class="dropdown-item">{{
                $t("expertise_3")
            }}</span></router-link>
            <router-link to="/expertise/copyright"><span class="dropdown-item">{{
                $t("expertise_4")
            }}</span></router-link>
            <!-- <div class="dropdown-divider"></div> -->
          </div>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/our-people">{{
              $t("our_people")
          }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/about">{{
              $t("about")
          }}</router-link>
        </li>
        <!-- <li class="nav-item dropdown">
          <div class="nav-link locale-wrapped d-flex" :class="{ text_color: scrollPosition > 100 }">
            <span class="pr-2"><i class="fa-solid fa-globe"></i></span>
            <select class="form-select form-select-sm locale-switcher" v-model="$i18n.locale">
              <option data-content="<i class='flag flag-united-states'></i>" aria-hidden="true" value="en">
                EN
              </option>
              <option data-content="<i class='flag flag-id'></i>" aria-hidden="true" value="id">
                ID
              </option>
            </select>
          </div>
        </li> -->
      </ul>
    </div>
    <!-- </v-container> -->
  </nav>
  <!-- <v-app-bar
    absolute
    color="#00000000"
    evelate-on-scroll
    scroll-target="#scrolling-techniques-3"
    height="100vh"
    app
  >
    <v-toolbar-title
      ><v-img src="https://bit.ly/3M0yRIT" height="80px" contain></v-img
    ></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-title class="mr-5 cursor-pointer" flat href="/about"
      >Patent</v-toolbar-title
    >
    <v-toolbar-title class="mr-5" flat href="/about">Trademark</v-toolbar-title>
    <v-toolbar-title class="mr-5" flat href="/about"
      >Industrial Design</v-toolbar-title
    >
    <v-toolbar-title class="mr-5" flat href="/about">Copyright</v-toolbar-title>
  </v-app-bar> -->
</template>

<script>
export default {
  name: "Header-2",
  data() {
    return {
      scrollPosition: null,
    };
  },
  // created() {
  //   window.addEventListener("scroll", this.scrollFunction());
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.scrollFunction());
  // },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    // scrollFunction() {
    //   var nav = document.getElementById("nav");
    //   if (window.pageYOffset > 100) {
    //     nav.style.background = "#007bff !important";
    //   } else {
    //     nav.style.background = "transparent !important";
    //   }
    // },
    //   if (
    //     document.body.scrollTop > 20 ||
    //     document.documentElement.scrollTop > 20
    //   ) {
    //     document.getElementsByClassName("navbar").style.background = "#501e27";
    //   } else {
    //     document.getElementsByClassName("navbar").style.background = "none";
    //   }
    // },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
/* .background-nav {
  width: 100vw;
  height: 106px;
  background-color: white;
} */
.router-link-exact-active>.dropdown-item {
  background-color: rgb(140, 61, 64) !important;
  color: #ffffff;
}

.router-link-active>#navbarDropdown {
  color: #c18f3b !important;
}

.router-link-exact-active {
  color: #c18f3b !important;
}

.change_color {
  background-color: rgb(140, 61, 64);
}

.text_color {
  color: white;
}

.navbar_text li:after {
  content: "";
  display: block;
  border-bottom: 2px solid white;
  transform: scaleX(0);
  transition: 0.2s ease-in-out;
}

.navbar_text li:hover:after {
  transform: scaleX(1);
}

a {
  color: #ffffff;
}

#nav {
  text-align: center;
}

.navbar {
  /* backdrop-filter: blur(5px) !important; */
  background-color: rgb(140, 61, 64);
  position: fixed;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 100;
}

.navbar-toggler {
  border: 1px solid #c7933e;
}

.navbar-nav li:after {
  content: "";
  display: block;
  border-bottom: 2px solid rgb(140, 61, 64);
  transform: scaleX(0);
  transition: 0.2s ease-in-out;
}

.navbar-nav li:hover:after {
  transform: scaleX(1);
}

.nav-link {
  font-size: 20px;
  margin-right: 20px;
  transition: none !important;
  color: #ffffff;
}

.nav-link:hover {
  color: #c18f3b !important;
}

.locale-wrapped {
  display: flex;
  justify-content: center;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
  pointer-events: none;
}

.dropdown-item:hover {
  color: white !important;
  background-color: rgb(140, 61, 64);
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .locale-switcher {
    max-width: 25%;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .locale-switcher {
    max-width: 25%;
  }
}

@media screen and (max-width: 480px) {
  .locale-switcher {
    max-width: 25%;
  }

  .v-card__text {
    height: auto;
  }
}

/* a.nav-link:hover {
  color: white !important;
  background-color: rgb(140, 61, 64);
  border-radius: 10px !important;
} */
</style>
