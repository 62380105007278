<template>
  <div>
    <Header />
    <v-app>
      <ScrollToTop />
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />

      <div class="container our-people-wrapped">
        <div class="row justify-content-center header-wrapped">
          <div class="col-12 col-sm-8 col-lg-6 font-weight-black my-10">
            <!-- Section Heading-->
            <h1 class="text-center font-weight-black">{{ $t("our_team") }}</h1>
          </div>
        </div>
        <div class="row mb-5 text-center justify-content-center">
          <h5 class="font-weight-black mb-5 justify-content-center">
            Registered IP Consultant
          </h5>
          <!-- Single Advisor-->
          <div class="card-people-wrapped col-12 col-sm-6 col-lg-3">
            <div
              class="single_advisor_profile wow fadeInUp"
              data-wow-delay="0.2s"
              style="
                visibility: visible;
                animation-delay: 0.2s;
                animation-name: fadeInUp;
              "
            >
              <!-- Team Thumb-->
              <!-- <div class="advisor_thumb"> -->
              <img
                src="~@/assets/people/IMG_8054 edit.webp"
                alt=""
                class="people"
              />
              <!-- Social Info-->
              <!-- <div class="social-info">
                  <a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a
                    href="#"><i class="fa fa-linkedin"></i></a>
                </div> -->
              <!-- </div> -->
              <!-- Team Details-->
              <div class="single_advisor_details_info">
                <p class="people-name">Ryan Hartono, S.E., S.H.</p>
                <small class="designation">Konsultan KI No. 201-2006</small>
              </div>
            </div>
          </div>

          <!-- Single Advisor-->
          <div class="card-people-wrapped col-12 col-sm-6 col-lg-3">
            <div
              class="single_advisor_profile wow fadeInUp"
              data-wow-delay="0.5s"
              style="
                visibility: visible;
                animation-delay: 0.5s;
                animation-name: fadeInUp;
              "
            >
              <!-- Team Thumb-->
              <!-- <div class="advisor_thumb"> -->
              <img
                src="~@/assets/people/IMG_7988 edit.webp"
                alt=""
                class="people"
              />
              <!-- Social Info-->
              <!-- <div class="social-info">
                  <a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a
                    href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div> -->
              <!-- Team Details-->
              <div class="single_advisor_details_info">
                <p class="people-name">Anna, S.Si., M.Si.</p>
                <small class="designation">Konsultan KI No. 878-2016</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5 text-center justify-content-center">
          <h5 class="font-weight-black mb-5">General Manager</h5>
          <!-- Single Advisor-->
          <div class="card-people-wrapped col-12 col-sm-6 col-lg-3">
            <div
              class="single_advisor_profile wow fadeInUp"
              data-wow-delay="0.4s"
              style="
                visibility: visible;
                animation-delay: 0.4s;
                animation-name: fadeInUp;
              "
            >
              <!-- Team Thumb-->
              <!-- <div class="advisor_thumb"> -->
              <img
                src="~@/assets/people/IMG_8006 edit.webp"
                alt=""
                class="people"
              />
              <!-- Social Info-->
              <!-- <div class="social-info">
                  <a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a
                    href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div> -->
              <!-- Team Details-->
              <div class="single_advisor_details_info">
                <p class="people-name">Astin Adinda Patriasih, S.P</p>
                <small class="designation">General Manager</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <h5 class="font-weight-black text-center mb-5">Supporting Staff</h5>
            <v-chip class="m-1">Bambang Sugiarto, S.Ak.</v-chip>
            <v-chip class="m-1">Arlita Budihapsari, S. Par.</v-chip>
            <v-chip class="m-1">Riana Putri Andaryanti S.I.Kom.</v-chip>
            <v-chip class="m-1">Dina Ayu Chandra</v-chip>
            <v-chip class="m-1">Nizerul Mutiah</v-chip>
            <v-chip class="m-1">Imelda Sapitri</v-chip>
            <v-chip class="m-1">Achmad Reza Baihaqi</v-chip>
            <v-chip class="m-1">Siti Nurlaila</v-chip>
            <v-chip class="m-1">Ichtiarsih</v-chip>
            <v-chip class="m-1">Irfan Faozi</v-chip>
          </div>
        </div>
      </div>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header-2";
import Footer from "@/components/Footer";
import ScrollToTop from "../components/ScrollToTop.vue";

export default {
  name: "OurPeople",
  components: {
    Header,
    Footer,
    ScrollToTop,
  },
};
</script>

<style scoped>
.attorney-col,
.finance-col {
  margin: auto;
  width: 50%;
}

.our-people-wrapped {
  margin-top: 6.5em;
  margin-bottom: 3.5em;
}

.header-wrapped {
  letter-spacing: 10px;
}

.people {
  width: 100%;
  /* height: 55vh; */
  object-fit: cover;
}

body {
  /* margin-top: 20px; */
  background: #eee;
}

.single_advisor_profile {
  position: relative;
  margin-bottom: 50px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  z-index: 1;
  border-radius: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
}

.single_advisor_profile .single_advisor_details_info p {
  font-size: 16px !important;
}

.single_advisor_profile .advisor_thumb {
  position: relative;
  z-index: 1;
  border-radius: 15px 15px 0 0;
  margin: 0 auto;
  overflow: hidden;
  height: 300px;
}

.single_advisor_profile .advisor_thumb::after {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  position: absolute;
  width: 150%;
  height: 80px;
  bottom: -45px;
  left: -25%;
  content: "";
  background-color: #ffffff;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

@media only screen and (max-width: 575px) {
  .single_advisor_profile .advisor_thumb::after {
    height: 160px;
    bottom: -90px;
  }
}

.single_advisor_profile .advisor_thumb .social-info {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0;
  right: 30px;
  text-align: right;
}

.single_advisor_profile .advisor_thumb .social-info a {
  font-size: 14px;
  color: #020710;
  padding: 0 5px;
}

.single_advisor_profile .advisor_thumb .social-info a:hover,
.single_advisor_profile .advisor_thumb .social-info a:focus {
  color: #3f43fd;
}

.single_advisor_profile .advisor_thumb .social-info a:last-child {
  padding-right: 0;
}

.single_advisor_profile .single_advisor_details_info {
  position: relative;
  z-index: 1;
  padding: 30px;
  text-align: right;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  border-radius: 0 0 15px 15px;
  background-color: #ffffff;
}

.single_advisor_profile .single_advisor_details_info::after {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  position: absolute;
  z-index: 1;
  width: 50px;
  height: 3px;
  background-color: #8c3d40;
  content: "";
  top: 12px;
  right: 30px;
}

.single_advisor_profile .single_advisor_details_info p {
  margin-bottom: 0.25rem;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_advisor_profile .single_advisor_details_info p {
    font-size: 14px;
  }
}

.single_advisor_profile .single_advisor_details_info p {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  margin-bottom: 0;
  font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_advisor_profile .single_advisor_details_info p {
    font-size: 12px;
  }
}

.single_advisor_profile:hover .advisor_thumb::after,
.single_advisor_profile:focus .advisor_thumb::after {
  background-color: #8c3d40;
}

.single_advisor_profile:hover .advisor_thumb .social-info a,
.single_advisor_profile:focus .advisor_thumb .social-info a {
  color: #ffffff;
}

.single_advisor_profile:hover .advisor_thumb .social-info a:hover,
.single_advisor_profile:hover .advisor_thumb .social-info a:focus,
.single_advisor_profile:focus .advisor_thumb .social-info a:hover,
.single_advisor_profile:focus .advisor_thumb .social-info a:focus {
  color: #ffffff;
}

.single_advisor_profile:hover .single_advisor_details_info,
.single_advisor_profile:focus .single_advisor_details_info {
  background-color: #8c3d40;
}

.single_advisor_profile:hover .single_advisor_details_info::after,
.single_advisor_profile:focus .single_advisor_details_info::after {
  background-color: #c6933c;
}

.single_advisor_profile:hover .single_advisor_details_info p,
.single_advisor_profile:focus .single_advisor_details_info p {
  color: #ffffff;
}

.single_advisor_profile:hover .single_advisor_details_info p,
.single_advisor_profile:focus .single_advisor_details_info p {
  color: #ffffff;
}

/* @media only screen and (min-width: 1441px) and (max-width: 2560px) {
  .people {
    height: 27vh;
  }
} */

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
  .people {
    height: 40vh;
  }
} */

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .attorney-col,
  .finance-col {
    width: 100%;
  }

  /* .people {
    height: 45vh;
  } */
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .card-people-wrapped {
    width: 50%;
  }

  /* .people {
    height: 49vh;
  } */

  .attorney-col,
  .finance-col {
    margin: auto;
    width: 100%;
  }

  .single_advisor_profile .single_advisor_details_info[data-v-4c90f6b1]::after {
    right: 2em;
  }
}

@media only screen and (max-width: 480px) {
  .card-people-wrapped {
    flex: 0 0 auto;
    width: 50%;
  }

  /* .people {
    height: 30vh;
  } */

  .title-job {
    margin-bottom: 1.5em !important;
  }

  .attorney-col,
  .finance-col {
    margin: auto;
    width: 100%;
  }

  .single_advisor_profile .single_advisor_details_info {
    padding: 28px 14px 14px;
    text-align: left;
    font-size: 12px;
  }

  .single_advisor_profile
    .single_advisor_details_info[data-v-4c90f6b1][data-v-4c90f6b1]::after {
    right: 6em;
  }
}

/* @media only screen and (width: 1024px) and (height: 1366px) {
  .people {
    height: 24vh;
  }
} */
</style>
